.slide-offer-form-content .body-layout-form {
    height: unset;
    overflow: unset;
}

.slide-offer-form-content .header-form {
    position: unset;
    display: none;
}

#slide-offer-form-content.slide-offer-form-content {
    position: unset !important;
}

.slide-offer-form-content .MuiLinearProgress-root {
    display: none;
}