.terms {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  -webkit-animation: animateFadeIn 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;
  animation: animateFadeIn 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0.5s both;

  &--header {
    display: flex;
    margin: 60px 0;

    img {
      -webkit-animation: animateWobble 0.8s 1.3s both;
      animation: animateWobble 0.8s 1.3s both;
    }

    h1 {
      color: var(--color-blue);
      font-size: 26px;
      margin: 10px 20px;
      line-height: 32px;
      font-weight: 600;

      span {
        display: block;
        font-weight: 900;
        font-size: 36px;
      }
    }
  }

  &--content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 40px 30px;
    width: 100%;
    border-radius: 8px;
    background: #fff;
    &--form {
      display: flex;
      flex-flow: column nowrap;
      margin: 30px 0;
      align-items: center;

      button {
        display: flex;
        justify-content: center;
        min-width: 320px;
        padding: 5px;
        background: #0165b2;
        color: #fff;
        margin-top: 40px;
        border-radius: 5px;
        border: none;
        transition: all 0.2s ease;

        &:disabled {
          cursor: not-allowed;
          background: var(--color-light-gray);
          color: var(--color-blue-gray);
        }

        &:hover:not(:disabled) {
          background-color: var(--color-blue);
        }
      }
    }

    h2 {
      color: var(--color-blue);
      font-size: 26px;
      font-weight: 600;
    }
  }
}
.box-info {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin: 30px 0;
  padding: 30px;
  box-shadow: 0px 2px 6px #26577d29;
  border-radius: 8px;
  -webkit-animation: animateFadeInLeft 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s both;
  animation: animateFadeInLeft 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s both;

  &--list {
    display: flex;
    &--item {
      display: flex;
      flex-flow: column nowrap;
      flex: 1;
      &--fragment {
        margin-bottom: 15px;
      }
    }
  }

  &--table {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;

    &--header {
      display: flex;
    }
    &--body {
      display: flex;
      flex-flow: column nowrap;
    }
    &--row {
      flex: 1;
      &:nth-child(4),
      &:nth-child(5) {
        flex: 2;
      }
    }
    &--column {
      display: flex;
    }
  }

  h3 {
    color: var(--color-blue);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  h4,
  small {
    color: var(--color-blue-gray-light);
    font-size: 14px;
    font-weight: normal;
  }
  p {
    color: var(--color-blue);
    font-size: 16px;
    font-weight: normal;
  }
}

.checkElement {
  display: flex;
  width: 700px;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;

  label {
    display: flex;
    align-items: center;
    color: var(--color-blue);
    font-size: 16px;
    font-weight: normal;

    input[type="checkbox"] {
      width: 18px;
      height: 18px;
      margin-right: 15px;
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 30px;
    border: 1px solid#CCC;
    border-radius: 4px;
    transition: all 0.2s ease;

    &:hover {
      background: var(--color-light-gray);
    }
  }
}

.modalBodyTerms {
  & > div {
    color: var(--color-blue);
    font-size: 16px;
    b {
      font-weight: 900;
    }

    &:nth-child(1) {
      margin-bottom: 20px;
    }
  }
}

/*
* keyframes para animações
*/

@-webkit-keyframes animateFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animateFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes animateFadeInLeft {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes animateFadeInLeft {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes animateWobble {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes animateWobble {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
