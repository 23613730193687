@use "sass:meta";
// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;0,800;1,400;1,700;1,800&display=swap');

// If you want to override variables do it here
@include meta.load-css('_variables');

@include meta.load-css('_login.scss');
// Import styles
@import '@coreui/coreui/scss/coreui.scss';
@import '~react-dates/lib/css/_datepicker.css';

// If you want to add something do it here
@include meta.load-css('custom');
// ie fixes
@include meta.load-css('ie-fix');

// Components
@include meta.load-css('_components');

@include meta.load-css('_default-containers');

@include meta.load-css('_calendar');
@include meta.load-css('_daily-summary');
@include meta.load-css('_loader');
@include meta.load-css('_time-line');
@include meta.load-css('_cards-component');
@include meta.load-css('_terms');
@include meta.load-css('_find-store');
@include meta.load-css('_default-view');
@include meta.load-css('_transaction');
@include meta.load-css('_pix');
@include meta.load-css('_possession-clain-modal');
@include meta.load-css('_notification');
@include meta.load-css('_dirf');

//Styles globais:
.MuiCheckbox-root {
  color: #cce1fa !important;
}
.MuiIconButton-colorSecondary {
  color: #0069e7 !important;
}

.MuiLinearProgress-colorPrimary {
  opacity: 0.5;
  background-color: #b3c4d0 !important;
}

.MuiLinearProgress-colorSecondary {
  background-color: #b3c4d0 !important;
}

.MuiLinearProgress-barColorPrimary {
  opacity: 1 !important;
  background-color: #002063 !important;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.MuiLinearProgress-barColorSecondary {
  opacity: 1 !important;
  background-color: #ffd000 !important;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.strong-text {
  font-weight: 600 !important;
}

p {
  margin-bottom: 0;
}

// scrollbar
#root {
  ::-webkit-scrollbar {
    width: 0;
    height: 0em;
    background-color: white;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--color-blue-gray);
    border-radius: 2.5em;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: var(--color-blue-gray-light);
  }
}

@media (max-width: 320px), (max-height: 640px) {
  html {
    font-size: 14px;
  }
}
