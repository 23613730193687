@use '_sizes.scss';
@use '_palette.scss';

.app.back-logo {
  background-repeat: no-repeat;
  background-size: cover;
  color: #666;
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: var(--color-blue);
    opacity: 0.9;
  }

  & input,
  & .input-group {
    height: 56px;
    font-size: 18px;
  }

  & button {
    padding: 10px 20px 10px 20px;
    font-size: 18px;
  }

  .container-logo {
    width: 22em;
    background-color: transparent;
    border: none;
    text-align: left;

    &:hover {
      background-color: transparent;
    }
    .title {
      width: 10em;
      padding: 0.9em 0 0.2em 1em;

      span + span {
        font-size: 1.4em;
        transform: translateY(-0.1em);
      }
    }
  }

  & .box-login {
    max-width: 33em;
    margin: auto;
    .error-text:after {
      content: '.';
      color: white;
    }
    .input-group {
      position: relative;
      &-text {
        background-color: transparent;
        border-color: var(--color-blue);
        border-radius: 0.8em 0 0 0.8em;
        border-right: 0;
      }
      &-prepend {
        z-index: 3;
      }
      input {
        position: relative;
        z-index: 2;
        background-color: transparent;
        padding: 1em 0.3em 0;
        box-shadow: none;
        color: var(--color-blue);
      }
      input:-internal-autofill-selected {
        box-shadow: 0 0 0px 1000px white inset;
        -webkit-text-fill-color: var(--color-blue);
      }
      input + span.placeholder {
        position: absolute;
        top: 0.88em;
        left: 2.9em;
        z-index: 1;
        transition: all ease 0.3s;
        color: var(--color-blue-gray);
      }
      input.full + span.placeholder,
      input:focus + span.placeholder,
      input:-internal-autofill-selected + span.placeholder {
        top: 0.6em;
        left: 4.5em;
        font-size: 12px;
        z-index: 2;
      }
      .error-icon {
        width: 1.5em;
        height: 1.5em;
        padding-left: 0.3em;
        font-size: 0.9em;
        display: block;
        background-color: var(--color-red);
        position: absolute;
        top: 1.1em;
        right: 1em;
        z-index: 2;
        color: white;
        text-align: center;
        font-style: normal;
        border-radius: 50%;
      }
    }
    .form-control {
      border-left: none;
      border-color: var(--color-blue);
      border-radius: 0 0.6em 0.6em 0;
    }
    .forgot-link {
      color: var(--color-blue-primary);
    }
    button {
      font-size: 16px;
      border-color: transparent;
      margin-left: 0 !important;
      background-color: var(--color-blue-primary);
      color: white;
      border-radius: 0.6em;

      &:hover {
        background-color: var(--color-blue-secondary);
      }
      &:disabled {
        background-color: var(--color-light-gray);
        color: var(--color-blue-gray);
      }

      &.link {
        display: inline-block;
        padding: 0;
        background-color: transparent;
        color: var(--color-blue-primary);
      }
    }
  }
}
