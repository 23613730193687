.component {
  &-searchbox {
    width: 22.3em;
    height: 2.5em;

    .btn:not(:disabled):not(.disabled) {
      margin: 0 !important;
      background-color: var(--color-blue);
      color: white;
      border: none;
    }
    .form-control {
      height: 100%;
    }
  }

  &-branchselect {
    width: 16.7em;

    &.show {
      display: inline-flex;
    }
    .dropdown-toggle:after {
      font: normal normal normal 12px/1 FontAwesome;
      content: '\f078';
      border: none;
    }
  }
  &-select {
    & > .dropdown-toggle {
      &.btn:not(:disabled):not(.disabled) {
        margin-left: 0 !important;
        margin-right: 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        color: var(--color-blue);
      }
      &:after {
        padding-left: 3em;
        font: normal normal normal 12px/1 FontAwesome;
        content: '\f078';
        border: none;
      }
    }
    &.show > .btn-secondary.dropdown-toggle {
      background-color: white;
    }
    & > .dropdown-menu {
      margin-top: -1px;
      min-width: 100%;
      overflow-y: scroll;
      max-height: 250px;

      .dropdown-item {
        border-bottom: none;
        color: var(--color-blue);
      }
    }
  }

  &-datepicker {
    &.show-calendar {
      .DayPicker {
        padding-bottom: 5em;
      }
    }
    &.hide-calendar {
      .DayPicker_wrapper__horizontal.DayPicker_wrapper__horizontal_1 {
        position: fixed;
        left: -100%;
        opacity: 0;
      }
      .apply-box {
        display: none;
      }
    }
    & > .DateRangePicker.DateRangePicker_1 {
      & > div .DateRangePickerInput {
        &__withBorder {
          margin-top: 0.1em;
          background-color: var(--color-blue-primary);
          border-radius: 0.4em;
          border-color: transparent;

          &:after {
            padding: 0 1.3em 0 3.5em;
            font: normal normal normal 0.8em/1 FontAwesome;
            content: '\f078';
            color: white;
          }
        }
        &_calendarIcon {
          margin: 0 0 0 1em;
          padding: 0.2em 0;
          color: white;
        }
        &_arrow {
          color: white;
        }

        .DateInput {
          width: 4.5em;
          background-color: transparent;

          &_input {
            font-size: 1.15em;
            text-align: center;
            padding: 0.25em 0.3em 0.2em;
            background-color: transparent;
            color: white;

            &__focused {
              border-bottom-color: #fff3;
              &::placeholder {
                color: #fff5;
              }
            }
          }
          &_fang {
            display: none;
          }
        }
      }
    }
    .DayPickerNavigation {
      display: flex;
      justify-content: space-between;
      & > div {
        width: 4em;
        height: 2.5em;
        margin-top: 0.9em;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-blue-primary);
      }
    }
    .DateRangePicker {
      &_picker {
        min-width: 100%;
        top: initial !important;
        z-index: 2;

        .CalendarMonth {
          &_caption {
            padding-top: 1.8em;
            padding-bottom: 2.4em;
            font-size: 0.9em;
          }
        }
        .DayPicker {
          width: auto !important;

          & > div {
            display: flex;
          }
          &_calendarInfo__horizontal {
            width: 16.5em !important;
            .nav {
              color: var(--color-blue);
            }
          }
          &_weekHeader {
            margin-top: -1.2em;

            &_li small {
              font-weight: 700;
              color: var(--color-blue);
              font-size: 0.9em;
            }
          }
        }
      }
      .DayPickerKeyboardShortcuts_show__bottomRight {
        display: none;
      }
      &Input_calendarIcon {
        &:before {
          content: ' ';
          display: block;
          width: 100%;
          height: 100%;
          background-color: transparent;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    .CalendarDay {
      &__default {
        border: none;
        font-family: 'Nunito', sans-serif;
        font-size: 0.9em;
        color: var(--color-blue-gray);
        &:hover {
          background-color: var(--color-blue-gray);
          color: white;
          border-color: transparent;
        }
      }
      &__selected {
        &_span {
          background-color: var(--color-light-gray);
          color: var(--color-blue-gray);
        }
        &_start,
        &_end {
          color: white;
        }
        &_start {
          border-radius: 0.3em 0 0 0.3em;
        }
        &_end {
          border-radius: 0 0.3em 0.3em 0;
        }
      }

      &__hovered_span,
      &__hovered_span:hover {
        background-color: var(--color-light-gray);
        color: var(--color-blue-gray);
      }

      &__selected,
      &__selected:active,
      &__selected:hover {
        background-color: var(--color-blue-secondary);
      }
      &__selected_span:active,
      &__selected_span:hover {
        background-color: var(--color-blue-primary);
        border-color: transparent;
      }
    }
    .apply-box {
      width: 100%;
      display: flex;
      position: absolute;
      z-index: 2;
      bottom: 0;
    }
  }

  &-table {
    z-index: 1;
    &.hide {
      animation: hideTable 0.4s linear forwards;
      z-index: 0;
    }
    &.show {
      animation: showTable 0.4s linear forwards;
      z-index: 0;
    }
    .ReactTable {
      min-width: 25em;
      min-height: 11em;
      border: none;
    }
    &-title {
      margin-bottom: 2px;
      background-color: var(--color-blue-gray-light-0);

      .collapse-button {
        top: 0;
        right: -2.2em;
        transform: scale(0.8);
        &:hover,
        &:focus:hover {
          background-color: #d6e3ef !important;
        }
        &:focus {
          background-color: white !important;
        }
        svg path {
          fill: var(--color-blue);
        }
      }
    }
    .rt {
      &-thead {
        .rt-th,
        .rt-td {
          &.-sort-asc {
            box-shadow: none;
          }
        }
        .rt-th {
          padding: 0.2em 1em;
          color: var(--color-blue);
        }
        .rt-tr {
          padding: 0 1em;
          .rt-th {
            padding: 0.8em 1.55em;
          }
        }
        &.-header {
          background-color: var(--color-blue-gray-light-0);
          box-shadow: none;
        }
        &.-filters {
          display: none;
        }
      }
      &-tbody {
        min-height: 6em;
        background-color: white;

        .rt-tr-group {
          border-bottom: 1.5px solid var(--color-border-table);
          .rt-tr {
            padding: 0 1em;
          }
        }
        .rt-td {
          border: none;
          height: 4.5em;
          padding: 0.6em 1.5em 0.4em;
          color: var(--color-blue);
          display: flex;
          align-items: center;

          .checked-cell:checked + label:before {
            content: 'Conferido';
            color: var(--color-blue);
          }
          .checked-cell + label:before {
            content: 'Não conferido';
            color: var(--color-blue-gray-light);
          }
        }
      }
    }
    .btn {
      &-download {
        width: 3em;
        height: 2.4em;
        border: 1px solid var(--color-light-gray);
        display: inline-block;
        padding: 0.35em 0.9em;
        color: var(--color-blue);
        border-radius: 0.5em;
        // background: url("/assets/icons/download-dark-icon.svg") center center no-repeat;
        text-indent: -9999px;
        overflow: hidden;

        &:hover {
          // background: var(--color-blue) url("/assets/icons/download-icon.svg") center center
          //   no-repeat;
          color: white;
        }
      }
      &-linktarget {
        border: none;
        background-color: transparent;
        color: var(--color-blue-primary);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &.dark {
      .component-table {
        &-title {
          background-color: var(--color-blue-gray-light-0);
        }
      }
      .rt-noData {
        padding: 0;
        background-color: transparent;
      }
      .rt-thead {
        &.-header {
          background-color: var(--color-blue-gray-light-0);
        }
      }
      .rt-tbody {
        background-color: var(--color-light-gray-0);
      }
    }
    &.light {
      .component-table {
        &-title {
          background-color: var(--color-light-gray);
        }
      }

      .rt-thead {
        &.-header {
          background-color: var(--color-light-gray);
        }
      }
      .rt-tbody {
        background-color: #fff;
        .rt-tr-group .rt-tr:hover {
          background-color: var(--color-light-gray-0);
        }
      }
    }
    .rounded .rt-table {
      border-radius: 0.6em;
    }
  }

  &-pagination {
    .btn,
    .btn:not(:disabled):not(.disabled) {
      min-width: 3.25em;
      margin: 0 !important;
      font-size: 0.9em;
      color: var(--color-blue);
    }
    .btn:not(:disabled):not(.disabled) {
      &.active,
      &:hover {
        background-color: var(--color-blue-primary) !important;
        color: white;
      }
    }
  }

  &-modal {
    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      border: transparent;
    }
    .modal {
      &-header {
        padding: 1.5em 2em 0;
        border-bottom-color: transparent;
      }
      &-body {
        padding: 1.5em 2em;
      }
      &-footer {
        padding: 0.7em 2em 1em;
      }
      &-title {
        color: var(--color-blue);
      }
    }
  }
  &-icon {
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    font-size: 0.8em;
    border-radius: 50%;
    font-style: normal;
    &:before {
      font-family: 'Nunito', sans-serif;
    }
    &-info {
      padding-top: 0.1em;
      &:before {
        content: 'i';
      }
    }
    &-danger {
      transform: translateY(-0.1em);
      text-align: center;
      &:before {
        content: '!';
      }
    }
  }

  &-loading {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 9998;
    background-color: rgba(235, 237, 240, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    &:before,
    &:after {
      content: ' ';
      display: block;
      width: 4.2em;
      height: 4.2em;
      background-color: var(--color-blue);
      position: absolute;
      border-radius: 50%;
      opacity: 0.5;
      animation-name: loadingLooping;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
    &:after {
      animation-direction: reverse;
    }
    &.fixed {
      position: fixed;
    }
    &.bg-none {
      background-color: transparent;
    }
  }
}

body {
  .MuiTooltip {
    &-arrow {
      color: white;
    }
    &-tooltip {
      padding: 0.6em 1em;
      background-color: white;
      color: var(--color-blue);
      font-size: 0.9em;
      box-shadow: 0 0.02em 0.8em rgba(0, 0, 0, 0.2);
    }
  }
  .component-table .rt-tbody .rt-tr-group .rt-tr:hover {
    background-color: var(--color-light-gray);
    .btn-download {
      background-color: var(--color-light-gray-0);
      &:hover {
        background-color: var(--color-blue);
      }
    }
  }
  .component-table .rt-tbody .rt-tr-group .rt-tr:hover .bg-white-column {
    background-color: var(--color-light-gray-0) !important;
  }
}

.menu-tab {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0 0 20px 0;
  border-bottom: 1px solid var(--color-blue-gray);

  li {
    list-style: none;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;

    &:hover {
      & > button {
        font-size: 16px;
        color: var(--color-blue);
        font-weight: 900;
      }
    }

    &.active {
      & > button {
        font-size: 16px;
        color: var(--color-blue);
        font-weight: 900;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -20px;
        width: 100%;
        height: 2px;
        background: var(--color-blue);
      }
    }
  }

  button {
    border: none;
    background-color: transparent;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--color-blue-gray);

    &:focus {
      outline: none;
    }
  }
}
.dropDownLastChildBlue {
  button {
    &:last-child {
      &:hover {
        background: var(--color-blue) !important;
      }
    }
  }
}

.dropdownMenuSite {
  h6 {
    font-size: 13px;
    font-weight: normal;
  }
  button {
    color: var(--color-blue);
    font-size: 15px;
    &:hover {
      color: #fff !important;
      background: var(--color-blue);
    }
    &:last-child {
      &:hover {
        background: var(--color-red);
      }
    }
  }
}

.company-collapse {
  &--header {
    cursor: pointer;
    transition: background 0.2s linear;
    border-bottom: 1px solid var(--color-light-gray);
    &:hover {
      background-color: #e6edf2;
    }
  }

  &:last-child {
    & > div {
      border-bottom: none;
    }
  }
}

@media (min-width: 920px) {
  .modal-dialog {
    max-width: 40em;
  }
}

@keyframes hideTable {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}
@keyframes showTable {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes loadingLooping {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
