.notification {
  background-color: #FE980B;
  border-radius: 0 0 8px 8px;
  color: white;
  width: 100%;
  position: absolute;
  top: 95px;
  left: 0;
  z-index: 999;
  box-sizing: content-box;
  box-shadow: 0px 3px 6px #00000029;
  padding-top: 10px;

  button.close {
    font-size: 2rem;
    color: white;
  }
  .card-body{
    padding: 20px ;
  }
  .fa-exclamation-circle{
    font-size:1.5rem;
  }
  .notification-pix-button {
    background-color: #FE980B;
    color: #fff;
    border: 1px solid #fff;
    align-self: flex-start;
  }
  .notification-pix-button:hover {
    background-color: #fff;
    color: #FE980B;
    border: 1px solid #FE980B;
    align-self: flex-start;
  }
  .notification-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
