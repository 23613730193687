@use '_palette.scss';
@use '_sizes.scss';

.payments-daily-summary {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-left: sizes.$size-30;
  padding-right: sizes.$size-30;
  background-color: palette.$white;
  height: 100%;

  .title {
    margin-top: 2.9em;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    div {
      font-size: sizes.$size-18;
      font-weight: 500;
      color: palette.$medium-grey;
    }
    .circle {
      border-radius: 50%;
      height: 14px;
      width: 14px;
      margin-left: 1em;
      &__green {
        background-color: palette.$green;
      }
      &__red {
        background-color: palette.$red;
      }
      &__yellow {
        background-color: palette.$yellow;
      }
      &__none {
        background-color: palette.$grey-font2;
      }
    }
  }
  .body {
    margin-bottom: 20px;
    .line {
      height: 41px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: sizes.$size-19;
      padding-right: sizes.$size-19;
      background-color: palette.$light-grey;
      margin-bottom: sizes.$size-10;
      border-radius: sizes.$size-3;
      .label {
        font-size: sizes.$size-14;
        color: palette.$grey-font2;
      }
      .value {
        font-size: sizes.$size-19;
        &__gray {
          color: palette.$dark-grey;
        }
        &__red {
          color: palette.$red;
        }
        &__orange {
          color: palette.$orange;
        }
        &__green {
          color: palette.$green;
        }
      }
      &__total {
        background-color: palette.$light-grey3;
        .label {
          display: flex;
          align-items: flex-end;
          div:last-child {
            margin-left: sizes.$size-3;
            font-size: sizes.$size-13;
          }
          div:first-child {
            color: palette.$dark-grey;
            font-size: sizes.$size-18;
            font-weight: bold;
          }
        }
      }
    }
  }

  .footer {
    color: palette.$dark-grey;
    letter-spacing: 0.5px;
    .label {
      font-size: sizes.$size-13;
      margin-bottom: 12px;
    }
    .text {
      font-size: sizes.$size-12;
      font-weight: 200;
    }
  }
}
.bank-account {
  font-size: sizes.$size-11;
}
.bank-name {
  font-size: sizes.$size-18;
  font-weight: bold;
}
.text-margin-left {
  margin-left: sizes.$size-40;
  font-weight: bold;
  &__gray {
    color: palette.$dark-grey;
  }
  &__Erro {
    color: palette.$red;
  }
  &__Solicitado,
  &__Pendente {
    color: palette.$orange;
  }
  &__Completado {
    color: palette.$green;
  }
}

.payments-daily-summary__nodata {
  justify-content: center;
  position: relative;
  right: 0;
  top: 0;
  .text {
    padding-left: sizes.$size-50;
    padding-right: sizes.$size-50;
    text-align: center;
    font-size: sizes.$size-18;
    color: palette.$grey-font2;
    font-weight: 300;
  }
}
